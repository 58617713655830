import {Selector, State} from '@ngxs/store'
import {DataAction, Payload, StateRepository} from '@angular-ru/ngxs/decorators'
import {Injectable} from '@angular/core'
import {AlertState} from './alert/alert.state'
import {EntityDictionary} from '@angular-ru/cdk/entity'
import {Alert, AlertDTO, AlertResolution, alertUrgencyComparator} from '../shared/model/alert'
import {PatientState} from './patient/patient.state'
import {PatientDTO} from '../shared/model/patient'
import {EntityCollation} from './root-store-common'
import {tap} from 'rxjs'
import {
  CreateGenericEntityRequest,
  GenericEntityAPIService,
  SearchResponseGenericEntityResponse
} from 'biot-client-generic-entity'
import {FileState} from './file/file.state'
import {FileDTO} from '../shared/model/file'
import {DeviceState} from './device/device.state'
import {DeviceDTO} from '../shared/model/device.model'
import {CreateAlertRule} from '../shared/model/alert-rules.model'
import {AlertRuleState} from './alert-rule/alert-rule.state'

@StateRepository()
@State({
	name: 'rootSelectors'
})
@Injectable()
export class RootStore {
	constructor(
		private genericEntityAPIService: GenericEntityAPIService,
		private alertState: AlertState,
		private patientState: PatientState,
		private alertRule: AlertRuleState
	) {}

	@Selector([AlertState.alerts, PatientState.allPatients])
	public static alertsTotalCount(
		state: EntityCollation,
		alerts: EntityDictionary<string, AlertDTO>
	): number {
		return Object.values(alerts).filter((a) => a.status === 'open').length
	}

	@Selector([AlertState.alerts])
	public static devicesAlertsCount(
		state: EntityCollation,
		alerts: EntityDictionary<string, AlertDTO>
	): number {
		return Object.values(alerts)
			.filter((a) => a.status === 'open')
			.filter((v: AlertDTO) => v.alertedDevice).length
	}

  @Selector([
    AlertState.alerts,
    PatientState.entities,
    FileState.files,
    DeviceState.entities
  ])
  public static hydratedAlertsWithoutEscalated(
    state: EntityCollation,
    alerts: EntityDictionary<string, AlertDTO>,
    patients: EntityDictionary<string, PatientDTO>,
    files: EntityDictionary<string, FileDTO>,
    devices: EntityDictionary<string, DeviceDTO>
  ): Alert[] {

    let result = Object.values(alerts)
      .filter(a => a.resolution != AlertResolution.Escalated)
      .map(a => {
      const patient: PatientDTO | undefined = a.patient?.id ? patients[a.patient.id]: undefined;
      const avatar = patient?.avatar?.id ? files[patient.avatar.id] : undefined;
      const device : DeviceDTO | undefined = a.alertedDevice?.id ? devices[a.alertedDevice.id] : undefined;

      return {
        ...a,
        patient: {
          ...a.patient,
          ...patient,
          ...{
            avatar: {
              ...patient?.avatar,
              ...avatar
            }
          }
        },
        alertedDevice:{
          ...a.alertedDevice,
          ...device
        }
      } as Alert
    })

    result.sort(alertUrgencyComparator);
    return result;
  }

	@Selector([
		AlertState.serverSideAlertsDTO,
		PatientState.allPatients,
		FileState.files,
		DeviceState.allDevices
	])
	public static alerts(
		state: EntityCollation,
		alerts: AlertDTO[],
		patients: PatientDTO[],
		files: EntityDictionary<string, FileDTO>,
		devices: DeviceDTO[]
	): Alert[] {
		const currentAlerts: Alert[] = []
		alerts.forEach((alert) => {
			if (
				alert.patient &&
				alert.patient.id &&
				alert.alertedDevice &&
				alert.alertedDevice.id
			) {
				const patient: PatientDTO | undefined = patients.find(
					(p) => p.id === alert.patient?.id
				)
				const device: DeviceDTO | undefined = devices.find(
					(d) => d.id === alert.alertedDevice?.id
				)
				if (patient && device) {
					currentAlerts.push({
						...alert,
						alertedDevice: {
							...device
						},
						patient: {
							...patient,
							avatar:
								patient.avatar &&
								files[patient.avatar.id] &&
								files[patient.avatar.id]?.signedUrl
									? files[patient.avatar.id]
									: null
						}
					})
				} else if (patient) {
					currentAlerts.push({
						...alert,
						patient: {
							...patient,
							avatar:
								patient.avatar &&
								files[patient.avatar.id] &&
								files[patient.avatar.id]?.signedUrl
									? files[patient.avatar.id]
									: null
						}
					})
				} else if (device) {
					currentAlerts.push({
						...alert,
						alertedDevice: {
							...device
						}
					})
				}
			} else if (alert.patient && alert.patient.id) {
				const patient: PatientDTO | undefined = patients.find(
					(p) => p.id === alert.patient?.id
				)
				if (patient) {
					currentAlerts.push({
						...alert,
						patient: {
							...patient,
							avatar:
								patient.avatar &&
								files[patient.avatar.id] &&
								files[patient.avatar.id]?.signedUrl
									? files[patient.avatar.id]
									: null
						}
					})
				}
			} else if (alert.alertedDevice && alert.alertedDevice.id) {
				const device: DeviceDTO | undefined = devices.find(
					(d) => d.id === alert.alertedDevice?.id
				)
				if (device) {
					currentAlerts.push({
						...alert,
						alertedDevice: {
							...device
						}
					})
				}
			} else {
				currentAlerts.push({
					...alert
				})
			}
		})
		return currentAlerts
	}

	@DataAction()
	public createAlertRule(
		@Payload('alertRule') alertRule: CreateAlertRule,
		@Payload('patientId') patientId: string
	) {
		return this.genericEntityAPIService
			.createGenericEntity(alertRule as CreateGenericEntityRequest)
			.pipe(
				tap((res) => {
					this.patientState.updatePatient(patientId, {
						alertRules: {
							id: res._id,
							name: res._name,
							templateId: 'c159c6bf-8aed-4965-b1cf-4b7015a19948'
						}
					})
					this.alertRule.addNewAlertRule(res)
				})
			)
	}

	@DataAction()
	public loadAllAlerts(
		@Payload('page') page: number,
		@Payload('freeTextFilter') freeTextSearch: string
	) {
		// @ts-ignore
		return this.genericEntityAPIService
			.searchGenericEntities({
				filter: {
					_templateId: {
						in: ['05d9c5d3-d854-4894-9fac-d02f166e11f8']
					},
					severity: {
						eq: 'critical'
					}
				},
				freeTextSearch,
				page,
				limit: 10
			})
			.pipe(
				tap((res: SearchResponseGenericEntityResponse) => {
					const patientIds = res.data
						.map((patient: any) => patient.id)
						.filter((i: string | any) => i)
					this.patientState.loadPatientImages(patientIds)
					this.alertState.patchState({
						allAlerts: res.data,
						totalCount: res.metadata.page?.totalResults
					})
				})
			)
	}
}
