import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AlertComponent } from './components/alert/alert.component'
import { InputsModule } from '../UI/inputs/inputs.module'
import { ButtonsModule } from '../UI/buttons/buttons.module'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzRadioModule } from 'ng-zorro-antd/radio'
import { FormsModule } from '@angular/forms'
import { NzFormModule } from 'ng-zorro-antd/form'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzUploadModule } from 'ng-zorro-antd/upload'
import { CoreModule } from '../../../core/core.module'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { NzAvatarModule } from 'ng-zorro-antd/avatar'
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox'
import { MomentModule } from 'ngx-moment'
import { NzInputNumberModule } from 'ng-zorro-antd/input-number'
import { TranslateModule } from '@ngx-translate/core'
import { NzSliderModule } from 'ng-zorro-antd/slider'
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker'

@NgModule({
	declarations: [
		AlertComponent
		// NewAlertComponent,
		// UserPopoverComponent,
		// AlertRulesComponent
	],
	imports: [
		CommonModule,
		InputsModule,
		ButtonsModule,
		NzIconModule,
		NzRadioModule,
		FormsModule,
		NzFormModule,
		NzButtonModule,
		NzInputModule,
		NzUploadModule,
		CoreModule,
		NzModalModule,
		NzSelectModule,
		NzAvatarModule,
		NzCheckboxModule,
		MomentModule,
		NzInputNumberModule,
		TranslateModule,
		NzSliderModule,
		NzTimePickerModule
	],
	exports: [
		AlertComponent
		// NewAlertComponent,
		// UserPopoverComponent,
		// AlertRulesComponent
	]
})
export class ModalsModule {}
