import {
	Directive,
	Input,
	OnChanges,
	TemplateRef,
	ViewContainerRef
} from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { UserInterface } from '../../shared/model/user.model'

@UntilDestroy()
@Directive({
	selector: '[isNdPermission]'
})
export class AiomedIsNdPermissionDirective implements OnChanges {
	@Input('isNdPermission') user: UserInterface | null

	constructor(
		private viewContainerRef: ViewContainerRef,
		private templateRef: TemplateRef<any>
	) {}

	ngOnChanges(): void {
		if (this.user && (this.user as any)._degree === 'NO_DEGREE') {
			this.viewContainerRef.clear()
		} else {
			this.viewContainerRef.clear()
			this.viewContainerRef.createEmbeddedView(this.templateRef)
		}
	}
}
