import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import { Injectable } from '@angular/core'
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories'
import { catchError, Observable, of, tap } from 'rxjs'
import {
	CaregiverAPIService,
	OrganizationUserResponse
} from 'biot-client-organization'
import { UserStateInterface } from './types/userState.interface'
import { FileState } from '../file/file.state'
import { GetCurrentUserResponse } from './types/getCurrentUserResponce'
import { UserDTO, UserInterface } from '../../shared/model/user.model'
import { AuthState } from '../auth/auth.state'
import { CaregiverResponse } from '../../../../biot-client-organization/src'
import { mapToVoid } from '@angular-ru/cdk/rxjs'
import {
	CreateGenericEntityRequest,
	GenericEntityAPIService,
	SearchResponseGenericEntityResponse,
	UpdateGenericEntityRequest
} from 'biot-client-generic-entity'

export const userFeatureName = 'user'

@StateRepository()
@State<UserStateInterface>({
	name: userFeatureName,
	defaults: {
		user: null,
		isLoading: false,
		messagingToken: null,
		userProfileId: null
	}
})
@Injectable()
export class UserState extends NgxsDataRepository<UserStateInterface> {
	constructor(
		private caregiverAPIService: CaregiverAPIService,
		private fileState: FileState,
		private authState: AuthState,
		private genericEntityAPIService: GenericEntityAPIService
	) {
		super()
	}

	@Selector([FileState.files])
	static currentUser(
		state: UserStateInterface,
		files: any
	): UserInterface | null {
		if (!state.user || !state.user.avatar || !Object.values(files).length) {
			return state.user
		}
		return {
			...state.user,
			signedUrl: files[state.user.avatar.id].signedUrl
		}
	}

	// @Selector()
	// static currentmessagingToken(state: UserStateInterface): string | null {
	// 	return state.messagingToken
	// }

	private static toUserDTO(res: GetCurrentUserResponse): UserDTO {
		return {
			...res,
			id: res._id,
			name: res._name,
			email: res._email,
			locale: res._locale,
			gender: res._gender,
			dateOfBirth: res._dateOfBirth,
			address: res._address,
			template: res._template,
			avatar: res.avatar,
			messagingToken: res.messagingToken
		}
	}

	public override ngxsOnInit() {
		this.authState.state$
			.pipe(
				tap((s) => {
					if (!s.mfaRequired) this.getCurrentUser()
					else {
						this.patchState({ user: null, isLoading: false })
					}
				})
			)
			.subscribe()
	}

	@DataAction({ subscribeRequired: false })
	public getCurrentUser(): Observable<OrganizationUserResponse | null> {
		this.ctx.patchState({
			isLoading: true
		})
		if (!this.authState.isAuthenticated()) {
			this.ctx.patchState({ user: null, isLoading: false })
			return of(null)
		}
		return this.caregiverAPIService.getCaregiverSelf().pipe(
			tap((res: CaregiverResponse) => {
				const user = UserState.toUserDTO(res)
				if (user.avatar) {
					this.fileState.loadEntities([user.avatar.id])
				}
				this.loadUserProfile(user)
				this.ctx.patchState({
					user,
					isLoading: false
				})
			}),
			catchError(() => {
				this.ctx.patchState({ user: null, isLoading: false })
				return of(null)
			})
		)
	}

	@DataAction()
	public updateUser(@Payload('data') data: UserDTO): Observable<void> {
		return (
			this.caregiverAPIService
				// @ts-ignore
				.updateCaregiver(this.getState().user.id, data)
				.pipe(
					tap((res: CaregiverResponse) => {
						const user = UserState.toUserDTO(res)
						if (user.avatar) {
							this.fileState.loadEntities([user.avatar.id])
						}
						this.ctx.patchState({
							user,
							isLoading: false
						})
					}),
					mapToVoid()
				)
		)
	}

	@DataAction()
	public loadUserProfile(@Payload('user') user: UserDTO): Observable<void> {
		// @ts-ignore
		// @ts-ignore
		return this.genericEntityAPIService
			.searchGenericEntities({
				filter: {
					_templateId: {
						// @ts-ignore
						in: ['4c99f0b3-86e5-425a-b50e-41a51fcea884']
					}
				}
			})
			.pipe(
				tap((res: SearchResponseGenericEntityResponse) => {
					const currentUserProfile: any = res.data.find(
						(res: any) => res.caregiver.id === user.id
					)
					this.patchState({
						messagingToken:
							!currentUserProfile || !currentUserProfile.messagingToken
								? null
								: currentUserProfile.messagingToken,
						userProfileId:
							!currentUserProfile || !currentUserProfile._id
								? null
								: currentUserProfile._id
					})
				}, mapToVoid)
			)
	}

	@DataAction()
	public createUserProfile(@Payload('messagingToken') messagingToken: string) {
		const user = this.getState().user
		if (!user) return of()
		return this.genericEntityAPIService
			.createGenericEntity({
				caregiver: {
					id: user?.id,
					name: `${user?.name.firstName} ${user?.name.lastName}`,
					templateId: user?.template?.id
				},
				messagingToken,
				_name: `${user?.name.firstName} ${user?.name.lastName} User Profile`,
				_templateId: '4c99f0b3-86e5-425a-b50e-41a51fcea884',
				_ownerOrganization: (user as any)._ownerOrganization
			} as CreateGenericEntityRequest)
			.pipe(
				tap((res) => {
					this.patchState({
						messagingToken,
						userProfileId: res._id
					})
				})
			)
	}

	@DataAction()
	public updateUserProfile(
		@Payload('messagingToken') messagingToken: string
	): Observable<void> {
		const userProfileId = this.getState().userProfileId
		if (!userProfileId) return of()
		return this.genericEntityAPIService
			.updateGenericEntity(
				userProfileId as string,
				{ messagingToken } as UpdateGenericEntityRequest
			)
			.pipe(
				tap((res) => {
					this.patchState({
						messagingToken,
						userProfileId: res._id
					})
				}),
				mapToVoid()
			)
	}

	@DataAction()
	public deleteUserProfile(): Observable<void> {
		const userProfileId = this.getState().userProfileId
		if (!userProfileId) return of()
		return this.genericEntityAPIService
			.deleteGenericEntity(userProfileId as string)
			.pipe(
				tap((res) => {
					this.patchState({
						messagingToken: null,
						userProfileId: null
					})
				}),
				mapToVoid()
			)
	}
}
