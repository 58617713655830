import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	OnInit
} from '@angular/core'
import { combineLatest, delay, Observable } from 'rxjs'
import { routingAnimation } from '../shared/animations/routing-animation'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { Select } from '@ngxs/store'
import { ErrorState } from '../store/error/error.state'
import { AuthState } from '../store/auth/auth.state'
import { PreferenceState } from '../store/preference/preference.state'
import { TranslateService } from '@ngx-translate/core'
import moment from 'moment/moment'
import { registerLocaleData } from '@angular/common'
import en from '@angular/common/locales/en'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { AlertState } from '../store/alert/alert.state'
import { PatientState } from '../store/patient/patient.state'
import { DepartmentState } from '../store/department/department.state'
import { NotificationState } from '../store/notification/notification.state'
import { NzModalService } from 'ng-zorro-antd/modal'

@UntilDestroy()
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [routingAnimation],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
	@Select(ErrorState.errorMessage)
	errorMessage$: Observable<string | null>
	@Select(AuthState.isAuthenticated)
	isAuthorized$: Observable<boolean>
	@Select(PreferenceState.notification)
	notification$: Observable<boolean>
	@Select(PreferenceState.language)
	language$: Observable<string>
	@HostBinding('@routingAnimation') private routing: any

	constructor(
		private errorState: ErrorState,
		private notification: NzNotificationService,
		private preferenceState: PreferenceState,
		private translate: TranslateService,
		private alertState: AlertState,
		private patientState: PatientState,
		private permissionState: DepartmentState,
		private notificationState: NotificationState,
		private modal: NzModalService
	) {
		translate.setDefaultLang('en')
	}

	ngOnInit(): void {
		this.initializeListeners()
		// this.notificationState.requestPermission()
	}

	initializeListeners(): void {
		this.errorMessage$.subscribe((message: string | null) => {
			if (!message) return
			this.notification.create('error', 'Error', message)
			setTimeout(() => this.errorState.clear(), 5000)
		})

		this.language$.subscribe((data: string | null) => {
			if (data) {
				moment.locale(data)
				registerLocaleData(data)
				this.translate.use(data)
				return
			}
			this.preferenceState.setLanguage(
				// @ts-ignore
				navigator.language !== 'en' || navigator.language !== 'es'
					? 'en'
					: navigator.language
			)
		})

		this.isAuthorized$.subscribe((auth) => {
			if (!auth) return
			this.patientState.loadEntities()
			this.permissionState.loadEntities()
			this.alertState.backendUpdates$.pipe(untilDestroyed(this)).subscribe()
		})

		combineLatest(this.isAuthorized$, this.notification$)
			.pipe(delay(2000))
			.subscribe((data) => {
				let [auth, notification] = [data[0], data[1]]
				if (!auth) return
				if (typeof notification === 'object') {
					this.modal.confirm({
						nzTitle: 'Do you want to receive notification for alerts?',
						nzOnOk: () => {
							this.preferenceState.setNotification(true)
							this.notificationState.requestPermission()
						},
						nzOnCancel: () => {
							this.preferenceState.setNotification(false)
						}
					})
				} else if (data) {
					this.notificationState.requestPermission()
				}
			})
	}
}
