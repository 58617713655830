import { NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { NgxsDataPluginModule } from '@angular-ru/ngxs'
import { NgxsRouterPluginModule } from '@ngxs/router-plugin'
import { ErrorStoreModule } from './error/error-store.module'
import { UserStoreModule } from './user/user-store.module'
import { FileStoreModule } from './file/file-store.module'
import { AlertStoreModule } from './alert/alert-store.module'
import { DeviceStoreModule } from './device/device-store.module'
import { MeasurementStoreModule } from './measurement/measurement-store.module'
import { PatientStoreModule } from './patient/patient-store.module'
import { SessionStoreModule } from './session/session-store.module'
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin'
import { AuthState } from './auth/auth.state'
import { AlertRuleStoreModule } from './alert-rule/alert-rule-store.module'
import { NotificationStoreModule } from './notification/notification-store.module'
import { DepartmentModule } from './department/department.module'
import { PreferenceState } from './preference/preference.state'
import { RootStore } from './root-store'
import { InsightStoreModule } from './insight/insight.module'
import { SignsIllnessStoreModule } from './signsIllness/signsIllness-store.module'
import { PatientLogStoreModule } from './patient-log/patient-log-store.module'
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
import {environment} from "../environments/environment";

@NgModule({
	declarations: [],
	imports: [
		// The sequence of initialization is important - otherwise Auth tokens are not initialized right from local storage
		NgxsModule.forRoot([AuthState, PreferenceState, RootStore]),
		NgxsStoragePluginModule.forRoot({
			key: [
				'auth.accessJwt',
				'auth.refreshJwt',
				'preference.departmentId',
				'preference.isCollapseMenu',
				'preference.soundNotifications',
				'preference.lg',
				'preference.notification'
			]
		}),
		NgxsDataPluginModule.forRoot(),
		NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),
		// NgxsLoggerPluginModule.forRoot({
		// 	disabled: environment.production
		// }),
		AlertStoreModule,
		DepartmentModule,
		PatientStoreModule,
		DeviceStoreModule,
		ErrorStoreModule,
		UserStoreModule,
		FileStoreModule,
		MeasurementStoreModule,
		SessionStoreModule,
		AlertRuleStoreModule,
		NotificationStoreModule,
		InsightStoreModule,
		SignsIllnessStoreModule,
		PatientLogStoreModule
	],
	exports: [],
	providers: []
})
export class RootStoreModule {}
