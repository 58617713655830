import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import {
	createEntityCollections,
	EntityDictionary
} from '@angular-ru/cdk/entity'
import { Injectable } from '@angular/core'
import { EMPTY, forkJoin, Observable, of, tap } from 'rxjs'
import {
	ObservationField,
	ObservationFields,
	ObservationStats,
	PatientObservationDTO
} from '../../shared/model/patient-observation'
import {
	GetAggregatedMeasurementsResponse,
	Measurement,
	MeasurementsAPIService
} from 'biot-client-measurement'
import { mapToVoid } from '@angular-ru/cdk/rxjs'
import { DeviceUpdateMqttMessage } from '../../shared/model/device.model'
import { iot, mqtt } from 'aws-iot-device-sdk-v2'
import { OnMessageCallback, QoS } from 'aws-crt/dist/common/mqtt'
import { TemporaryCredentialsAPIService } from 'biot-client-device'
import {
	dataShaping,
	subtractHours,
	subtractMinusHours,
	subtractPlusHours
} from '../../core/helpers/functions'
import { cloneDeep, orderBy } from 'lodash-es'
import moment from 'moment/moment'
import { BackendPatientDTO } from '../../shared/model/backend-device-model'
import { PatientDTO } from '../../shared/model/patient'

export const measurementFeatureName = 'measurement'

@StateRepository()
@State<CollatableEntityCollections<PatientObservationDTO>>({
	name: measurementFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation()
	}
})
@Injectable()
export class MeasurementState extends CollatableEntityCollectionsRepository<
	PatientObservationDTO,
	EntityCollation
> {
	measurementsWSSubscriptions: any[] = []
	protected ectTransformData: { value: number; timestamp: string | Date }[] = []
	protected ecgDate: {
		timestamp: string | Date
		frequency: number
		data: number[]
	}[] = []
	protected ecgCount: number = 0

	constructor(
		private measurementsAPIService: MeasurementsAPIService,
		private temporaryCredentialsAPIService: TemporaryCredentialsAPIService
	) {
		super()
	}

	// public get backendUpdates$(): Observable<number> {
	// 	return timer(0, 60000).pipe(
	// 		switchMap((n) => {
	// 			return this.loadPatientObservations(this.ids).pipe(
	// 				concatMap((_) => of(n))
	// 			)
	// 		})
	// 	)
	// }

	@Selector()
	public static measurement(
		state: CollatableEntityCollections<PatientObservationDTO>
	): EntityDictionary<string, PatientObservationDTO> {
		return state.entities
	}

	@Selector()
	public static measurementHistorical(
		state: CollatableEntityCollections<PatientObservationDTO>
	): EntityDictionary<string, PatientObservationDTO> | null {
		return state.historicalMeasurements
	}

	@Selector()
	public static measurementHistoricalEcg(
		state: CollatableEntityCollections<PatientObservationDTO>
	): { value: number; timestamp: string | Date }[] | [] {
		return state.historicalEcg
	}

	@Selector()
	public static substrHours(
		state: CollatableEntityCollections<PatientObservationDTO>
	): Date {
		return subtractHours(state.subtractHours)
	}

	private static toPatientObservationDTO(
		id: string,
		s: GetAggregatedMeasurementsResponse
	): PatientObservationDTO {
		let tmpMeasurementsArray: {
			timestamp: string
			average: number
			standardDeviation: number
		}[] = []
		const latestMeasurements: [string, Measurement | any][] = s.attributes.map(
			(a) => {
				tmpMeasurementsArray = []
				a.sources.forEach((data) =>
					data.measurementSessions.forEach((m) =>
						tmpMeasurementsArray.push(m.measurements as any)
					)
				)
				return [
					a.attributeName,
					orderBy(tmpMeasurementsArray.flat(), 'timestamp', 'asc')
				]
			}
		)
		const latestStats: { [x: string]: any; timestamp: Date }[] = []
		latestMeasurements.forEach((data: [string, ObservationStats[]]) => {
			const [key, observationStats] = data
			observationStats.forEach((stat: ObservationStats) => {
				const idx = latestStats.findIndex((i) => i.timestamp === stat.timestamp)
				if (idx !== -1) {
					latestStats[idx][data[0]] = stat.average
				} else {
					latestStats.push({
						[key]: stat.average,
						timestamp: stat.timestamp
					})
				}
			})
		})
		return <PatientObservationDTO>{
			id: id,
			latest: orderBy(latestStats, 'timestamp', 'asc')
		}
	}

	@DataAction()
	public loadPatientObservations(@Payload('patientIds') patientIds: string[]) {
		this.ctx.patchState({
			entities: {}
		})
		return forkJoin(
			patientIds.map((i) =>
				this.measurementsAPIService.getMeasurements({
					attributes: Object.values(ObservationFields),
					patientId: i,
					binIntervalSeconds: 60,
					startTime: subtractHours(
						this.ctx.getState().subtractHours
					).toISOString(),
					endTime: new Date().toISOString()
				})
			)
		).pipe(
			tap((a) => this.replaceAllPatientObservations(patientIds, a)),
			mapToVoid()
		)
	}

	@DataAction()
	public async lastObservations(
		@Payload('patients') patients: BackendPatientDTO[] | PatientDTO[]
	) {
		const tmpObj: { id: string; latest: ObservationField[] }[] = []
		patients
			.map((p) => ({
				// @ts-ignore
				id: !p.id ? p._id : p.id,
				observation: [
					{ key: ObservationFields.Activity, ...p.activity },
					{ key: ObservationFields.BloodGlucose, ...p.bloodGlucose },
					{ key: ObservationFields.HeartRate, ...p.heart_rate },
					{ key: ObservationFields.BodyTemperature, ...p.body_temperature },
					{ key: ObservationFields.Posture, ...p.posture },
					{ key: ObservationFields.RespirationRate, ...p.respiration_rate },
					{ key: ObservationFields.SpO2, ...p.spo2 },
					{ key: ObservationFields.DiastolicPressure, ...p.diastolicPressure },
					{ key: ObservationFields.SystolicPressure, ...p.systolicPressure },
					{ key: ObservationFields.ExitBedRisk, ...p.exitBedRisk }
				].filter((el) => el.value)
			}))
			.forEach((p) => {
				if (!p.observation.length) return
				const el = tmpObj.find((o) => o.id === p.id)
				if (!el) tmpObj.push({ id: p.id, latest: [] })
				setTimeout(() => {
					p.observation.forEach((o) => {
						const idx = tmpObj.findIndex((o) => o.id === p.id)
						const observationIdx = tmpObj[idx].latest.findIndex(
							(ob) => ob.timestamp === o.timestamp
						)
						if (!tmpObj[idx].latest.length || observationIdx === -1) {
							// @ts-ignore
							tmpObj[idx].latest.push({
								[o.key]: o.value,
								timestamp: o.timestamp
							})
							this.upsertOne({
								id: p.id,
								latest: orderBy(
									[...cloneDeep(tmpObj[idx].latest)],
									'timestamp',
									'asc'
								)
							})
							return
						}
						// @ts-ignore
						tmpObj[idx].latest[observationIdx][o.key] = o.value
						this.upsertOne({
							id: p.id,
							latest: orderBy(
								[...cloneDeep(tmpObj[idx].latest)],
								'timestamp',
								'asc'
							)
						})
					})
				}, 0)
			})
		return of()
	}

	@DataAction()
	public loadHistoricalObservations(
		@Payload('patientId') patientId: string,
		@Payload('date') date: Date,
		@Payload('hours') hours: number
	) {
		return this.measurementsAPIService
			.getMeasurements({
				attributes: Object.values(ObservationFields),
				patientId,
				binIntervalSeconds: 60,
				startTime: subtractMinusHours(hours, date).toISOString(),
				endTime: subtractPlusHours(hours, date).toISOString()
			})
			.pipe(
				tap((a) => {
					this.patchState({
						historicalMeasurements: {
							[patientId]: MeasurementState.toPatientObservationDTO(
								patientId,
								a
							)
						}
					})
				}),
				mapToVoid()
			)
	}

	@DataAction()
	public loadObservationEcg(
		@Payload('patientId') patientId: string,
		@Payload('date') date: Date
	) {
		return this.measurementsAPIService
			.getRawMeasurements({
				attributes: ['ecg'],
				patientId,
				startTime: moment(date)
					.second(new Date(date).getSeconds() - 5)
					.toISOString(),
				endTime: moment(date).toISOString()
			})
			.pipe(
				tap((a) => {
					this.ectTransformData = []
					this.ecgDate = []
					this.ecgCount = 0
					if (!a.attributes.length) {
						this.ctx.patchState({
							historicalEcg: []
						})
						return
					}

					a.attributes[0].sources.forEach((item) =>
						item.measurementSessions.forEach((ms: any) => {
							ms.measurements.forEach((m: any) => this.ecgDate.push(m))
						})
					)

					this.setEcgDataSettings(
						this.ecgDate[this.ecgCount].data,
						this.ecgDate[this.ecgCount].timestamp,
						this.ecgDate[this.ecgCount].frequency
					)
				}),
				mapToVoid()
			)
	}

	@DataAction()
	setMeasurementsWSSubscriptions() {
		return this.temporaryCredentialsAPIService
			.getTempCredentialsForOrganizationClient()
			.pipe(
				tap((cred) => {
					// this.setMeasurementsWSDisconnect()
					const config =
						iot.AwsIotMqttConnectionConfigBuilder.new_with_websockets()
							.with_clean_session(true)
							.with_client_id(`pub_sub_sample(${new Date()})`)
							.with_endpoint(cred.endpoint!)
							.with_credentials(
								'us-east-1',
								cred.credentials?.accessKeyId!,
								cred.credentials?.secretAccessKey!,
								cred.credentials?.sessionToken!
							)
							.with_keep_alive_seconds(30)
							.build()
					const client = new mqtt.MqttClient()
					const connection = client.new_connection(config)
					connection.on('connect', () => {
						connection.subscribe(
							(cred as any).topic,
							1,
							this.measurementsWSOnMessageCallback
						)
						this.measurementsWSSubscriptions.push(connection)
					})
				})
			)
			.subscribe()
	}

	public setMeasurementsWSDisconnect() {
		if (!this.measurementsWSSubscriptions.length) return
		this.measurementsWSSubscriptions.forEach((connection) =>
			connection.disconnect()
		)
		this.measurementsWSSubscriptions = []
	}

	protected setPaginationSetting(): Observable<any> {
		throw new Error('Method not implemented.')
	}

	protected measurementsWSOnMessageCallback: OnMessageCallback = (
		topic: string,
		payload: ArrayBuffer,
		dup: boolean,
		qos: QoS,
		retain: boolean
	): void => {
		const decoder = new TextDecoder()
		const messageString = decoder.decode(payload)
		const message = JSON.parse(messageString) as DeviceUpdateMqttMessage
		const outMessage: any = {
			...message.data,
			...message.metadata,
			timestamp: new Date(message.metadata.timestamp).toISOString()
		}
		const measurementEntities = cloneDeep(this.getState().entities)
		if (measurementEntities[outMessage.patientId]) {
			const patientObservationFields: ObservationField[] = (measurementEntities[
				outMessage.patientId
			].latest = [
				...measurementEntities[outMessage.patientId].latest,
				{ ...outMessage }
			].filter(
				(el: ObservationField) =>
					el.timestamp >=
					subtractHours(this.getState().subtractHours).toISOString()
			))
			this.upsertOne({
				id: outMessage.patientId,
				latest: orderBy([...patientObservationFields], 'timestamp', 'asc')
			})
			return
		}
		this.upsertOne({
			id: outMessage.patientId,
			latest: [{ ...outMessage }]
		})
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return EMPTY
	}

	private replaceAllPatientObservations(
		patientIds: string[],
		r: GetAggregatedMeasurementsResponse[]
	) {
		const observations = r.map((value, index) =>
			MeasurementState.toPatientObservationDTO(patientIds[index], value)
		)
		this.setMany(observations)
	}

	private setEcgDataSettings(
		data: number[],
		timestamp: string | Date,
		frequency: number
	) {
		let t = timestamp
		data.reverse().forEach((value: number, idx: number) => {
			this.ectTransformData.push({
				value: value,
				timestamp: (t = idx === 0 ? timestamp : dataShaping(t, frequency))
			})
			this.ectTransformData = orderBy(this.ectTransformData, 'timestamp', 'asc')
			if (
				idx === data.length - 1 &&
				this.ecgDate[this.ecgCount + 1] &&
				this.ecgDate[this.ecgCount + 1].data.length
			) {
				this.ecgCount += 1
				this.setEcgDataSettings(
					this.ecgDate[this.ecgCount].data,
					this.ecgDate[this.ecgCount].timestamp,
					this.ecgDate[this.ecgCount].frequency
				)
			}
			if (!this.ecgDate[this.ecgCount + 1]) {
				this.ctx.patchState({
					historicalEcg: orderBy(this.ectTransformData, 'timestamp', 'asc')
				})
			}
		})
	}
}
