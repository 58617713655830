import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import { AlertDTO, alertSeverityComparator } from '../../shared/model/alert'
import { StateRepository } from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import {
	createEntityCollections,
	EntityDictionary
} from '@angular-ru/cdk/entity'
import {
	DeviceDTO,
	DeviceInterface,
	DeviceModality,
	DeviceModel,
	deviceSort
} from '../../shared/model/device.model'
import {
	DeviceAPIService,
	DeviceResponse,
	SearchResponseDeviceResponse
} from 'biot-client-device'
import {
	concatMap,
	forkJoin,
	Observable,
	of,
	switchMap,
	tap,
	timer
} from 'rxjs'
import { AlertState } from '../alert/alert.state'
import { Injectable } from '@angular/core'
import { BackendDeviceDTO } from '../../shared/model/backend-device-model'
import { mapToVoid } from '@angular-ru/cdk/rxjs'
import {
	UsageSessionDTO,
	UsageSessionState as UsageSessionStateEnum
} from '../../shared/model/usage-session'
import { SessionState } from '../session/session.state'
import { cloneDeep, isEqual } from 'lodash-es'
import { PAGE_SIZE } from '../../core/helpers/variables'
import { entitiesDeviceFilter } from '../../core/helpers/filter'
import { PatientState } from '../patient/patient.state'
import { PatientDTO } from '../../shared/model/patient'
import { FileState } from '../file/file.state'
import { FileDTO } from '../../shared/model/file'

export const deviceFeatureName = 'device'

@StateRepository()
@State<CollatableEntityCollections<DeviceDTO>>({
	name: deviceFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation()
	}
})
@Injectable()
export class DeviceState extends CollatableEntityCollectionsRepository<
	DeviceDTO,
	EntityCollation
> {
	constructor(
		private deviceAPIService: DeviceAPIService,
		private alertState: AlertState,
		private usageSessionState: SessionState,
		private patientState: PatientState
	) {
		super()
	}

	public get backendUpdates$(): Observable<number> {
		return timer(0, 60000).pipe(
			switchMap((n) => this.loadEntities().pipe(concatMap((_) => of(n))))
		)
	}

	@Selector([AlertState.alerts, SessionState.usageSession])
	public static devicesDisconnected(
		state: CollatableEntityCollections<DeviceDTO>,
		alerts: EntityDictionary<string, AlertDTO>,
		usageSessions: EntityDictionary<string, UsageSessionDTO>
	): number {
		return Object.values(state.entities)
			.map((device: DeviceDTO) =>
				DeviceState.hydrate(
					device,
					Object.values(alerts),
					Object.values(usageSessions)
				)
			)
			.filter((device) => {
				return !device.isConnected && !device.isTransmitting
			}).length
	}

	@Selector([
		AlertState.alerts,
		SessionState.usageSession,
		PatientState.allPatients,
		FileState.files
	])
	public static devices(
		state: CollatableEntityCollections<DeviceDTO>,
		alerts: EntityDictionary<string, AlertDTO>,
		usageSessions: EntityDictionary<string, UsageSessionDTO>,
		patients: PatientDTO[],
		files: EntityDictionary<string, FileDTO>
	): DeviceInterface[] {
		const currentDevices: DeviceDTO[] = []
		Object.values(state.entities).forEach((device) => {
			if (device.patient && device.patient.id) {
				const patient: PatientDTO | undefined = patients.find(
					(p) => p.id === device.patient?.id
				)
				if (patient) {
					currentDevices.push({
						...device,
						patient: {
							...patient,
							avatar:
								patient.avatar &&
								files[patient.avatar.id] &&
								files[patient.avatar.id]?.signedUrl
									? files[patient.avatar.id]
									: null
						}
					})
				}
			} else {
				currentDevices.push({
					...device
				})
			}
		})
		const tmpArray = currentDevices.map((device: DeviceDTO) =>
			DeviceState.hydrate(
				device,
				Object.values(alerts).filter((a) => a.status === 'open'),
				Object.values(usageSessions)
			)
		)
		return entitiesDeviceFilter(
			state.freeTextFilter,
			deviceSort(state.sort, tmpArray)
		).slice(state.pageSize - PAGE_SIZE, state.pageSize)
	}

	@Selector()
	public static allDevices(
		state: CollatableEntityCollections<DeviceDTO>
	): DeviceDTO[] {
		return Object.values(state.entities)
	}

  @Selector()
  public static entities(
    state: CollatableEntityCollections<DeviceDTO>
  ){
    return state.entities;
  }

	@Selector()
	public static totalCount(
		state: CollatableEntityCollections<DeviceDTO>
	): number {
		return Object.values(state.entities).length
	}

	@Selector([PatientState.allPatients])
	public static devicePaginateCount(
		state: CollatableEntityCollections<DeviceDTO>
	): number {
		return entitiesDeviceFilter(
			state.freeTextFilter,
			Object.values(state.entities)
		).length
	}

	@Selector()
	public static isLoading(
		state: CollatableEntityCollections<DeviceDTO>
	): boolean {
		return state.isLoading
	}

	private static hydrate(
		device: DeviceDTO,
		alerts: AlertDTO[],
		usageSessions: UsageSessionDTO[]
	): DeviceInterface {
		const deviceAlerts = alerts
			.filter((a: AlertDTO) => a.alertedDevice?.id == device.id)
			.sort((a: AlertDTO, b: AlertDTO) =>
				alertSeverityComparator(a.severity, b.severity)
			)
		const isTransmitting = usageSessions.some(
			(s) =>
				s.device?.id == device.id && s.state == UsageSessionStateEnum.Active
		)
		return {
			...device,
			alerts: deviceAlerts,
			maxAlertSeverity: deviceAlerts.length ? deviceAlerts[0].severity : null,
			isTransmitting: isTransmitting
		}
	}

	private static toDeviceDTO(res: BackendDeviceDTO): DeviceDTO {
		return {
			...res,
			id: res._id,
			name: res._description || '',
			patient: res._patient,
			modality: res.modality as DeviceModality,
			model: res.model as DeviceModel,
			serialNumber: res.serialNumber,
			isConnected: res._status?._connection?._connected || false,
			batteryLevel: (res._status as any).battery_level || null,
			statusInformation: res._status?._operational?._message || null,
			lastMeasurementTime: (res._status as any).last_measurement_time || null,
			lastStatusUpdate: (res._status as any).last_update_time || null
		}
	}

	protected setPaginationSetting(type?: string): Observable<any> {
		const state = this.ctx.getState()
		const devices = cloneDeep(Object.values(state.entities))
		const patientIds = devices
			.map((patient: any) => patient.id)
			.filter((i: string | any) => i)
		this.patientState.loadPatientImages(patientIds)
		let deviceHaveAlert: DeviceDTO[] = []
		if (type && type === 'haveAlert') {
			// @ts-ignore
			const ids: string[] = this.alertState.entitiesArray
				.sort((a, b) => alertSeverityComparator(a.severity, b.severity))
				.map((alert: AlertDTO) => alert.alertedDevice?.id)
				.filter((i) => i)
			devices.forEach((device) => {
				const idx = ids.findIndex((id: string) => id === device.id)
				if (idx === -1) return
				deviceHaveAlert = [...deviceHaveAlert, device]
			})
			this.setDeviceUsageSessionsSetting(
				deviceHaveAlert.slice(state.pageSize - PAGE_SIZE, state.pageSize)
			)
		} else {
			this.setDeviceUsageSessionsSetting(
				devices.slice(state.pageSize - PAGE_SIZE, state.pageSize)
			)
		}
		return of()
	}

	protected loadEntitiesFromBackend(): Observable<void> {
		const state = this.ctx.getState()
		return this.deviceAPIService.searchDevices({}).pipe(
			tap((res: SearchResponseDeviceResponse) => {
				const stateDevice = Object.values(this.ctx.getState().entities)
				const devices = res.data.map((device: DeviceResponse) =>
					DeviceState.toDeviceDTO(device as BackendDeviceDTO)
				)
				const equal: boolean = isEqual(stateDevice, devices)
				if (equal) return
				this.ctx.patchState({
					totalCount: state.freeTextFilter
						? state.totalCount
						: res.metadata.page?.totalResults!
				})
				this.upsertAllDevices([...res.data])
				this.setPaginationSetting()
				this.setPaginationSetting('haveAlert')
			}),
			mapToVoid()
		)
	}

	private setDeviceUsageSessionsSetting(data: DeviceDTO[]) {
		const deviceIds = data.map((device: DeviceDTO) => device.id)
		return forkJoin([this.usageSessionState.loadDeviceUsageSessions(deviceIds)])
	}

	private upsertAllDevices(res: DeviceResponse[]) {
		const devices = res.map((device: DeviceResponse) =>
			DeviceState.toDeviceDTO(device as BackendDeviceDTO)
		)
		this.upsertMany(devices)
	}
}
