import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { NotAuthenticated } from '../core/guards/not-authenticated.guard'
import { IsAuthenticated } from '../core/guards/is-authenticated.guard'
import { ROUTE_DATA } from '../core/helpers/variables'

const routes: Routes = [
	{
		path: 'login',
		canActivate: [NotAuthenticated],
		canLoad: [NotAuthenticated],
		loadChildren: () =>
			import('./pages/auth/auth.module').then((m) => m.AuthModule)
	},
	{
		path: '',
		canLoad: [IsAuthenticated],
		canActivate: [IsAuthenticated],
		data: {
			...ROUTE_DATA.patients
		},
		loadChildren: () =>
			import('./pages/patients/patients.module').then((m) => m.PatientsModule)
	},
	// {
	// 	path: 'all-patients',
	// 	canLoad: [IsAuthenticated],
	// 	canActivate: [IsAuthenticated],
	// 	data: {
	// 		...ROUTE_DATA.all_patients
	// 	},
	// 	loadChildren: () =>
	// 		import('./pages/all-patients/all-patients.module').then(
	// 			(m) => m.AllPatientsModule
	// 		)
	// },
	{
		path: 'patient/:patientId',
		canLoad: [IsAuthenticated],
		canActivate: [IsAuthenticated],
		data: {
			...ROUTE_DATA.patient
		},
		loadChildren: () =>
			import('./pages/patient/patient.module').then((m) => m.PatientModule)
	}
	// {
	// 	path: 'devices',
	// 	canLoad: [IsAuthenticated],
	// 	canActivate: [IsAuthenticated],
	// 	data: {
	// 		...ROUTE_DATA.devices
	// 	},
	// 	loadChildren: () =>
	// 		import('./pages/devices/devices.module').then((m) => m.DevicesModule)
	// },
	// {
	// 	path: 'alerts',
	// 	canLoad: [IsAuthenticated],
	// 	canActivate: [IsAuthenticated],
	// 	data: {
	// 		...ROUTE_DATA.alerts
	// 	},
	// 	loadChildren: () =>
	// 		import('./pages/alerts/alerts.module').then((m) => m.AlertsModule)
	// }
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
