import { Injectable } from '@angular/core'
import { CanActivate, CanLoad, Router } from '@angular/router'
import { AuthState } from '../../store/auth/auth.state'

@Injectable({
	providedIn: 'root'
})
export class NotAuthenticated implements CanActivate, CanLoad {
	constructor(private router: Router, private authState: AuthState) {}

	canActivate() {
		return this.activate()
	}

	canLoad() {
		return this.activate()
	}

	private activate() {
		if (this.authState.isAuthenticated() && !this.authState.isMfaRequired()) {
			this.router.navigateByUrl('/')
			return false
		} else {
			return true
		}
	}
}
