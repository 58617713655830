import { Id } from './common'
import {PatientDTO, PatientIdEmbed, PatientInterface} from './patient'
import {DeviceDTO, DeviceIdEmbed} from './device.model'

export interface AlertId extends Id {}

export interface AlertIdEmbed extends Id {
	name: string
}

export enum AlertSubject {
	BodyTemperature = 'body_temperature',
	Device = 'device',
	DeviceBattery = 'device_battery',
	General = 'general',
	HeartRate = 'heart_rate',
	Posture = 'posture',
	RespirationRate = 'respiration_rate',
	SystolicPressure = 'systolic_pressure',
	DiastolicPressure = 'diastolic_pressure',
	ExitBedRisk = 'exit_bed_risk',
	BloodGlucose = 'blood_glucose',
	SPO2 = 'spo2',
	Mobility = 'mobility'
}

export enum AlertStatus {
	Archived = 'archived',
	Closed = 'closed',
	Open = 'open',
	Snoozed = 'snoozed',
}

export enum AlertSeverity {
	Critical = 'critical',
	Major = 'major',
	Minor = 'minor',
	Notice = 'notice',
	Info = 'info'
}

export function alertSeverityComparator(
	a: AlertSeverity,
	b: AlertSeverity
): number {
	return (
		Object.values(AlertSeverity).indexOf(a) -
		Object.values(AlertSeverity).indexOf(b)
	)
}

export function alertUrgencyComparator(
  a: AlertDTO,
  b: AlertDTO
): number {
  return alertSeverityComparator(a.severity, b.severity)
    || (a.creationTime.getTime() - b.creationTime.getTime());
}

export function alertPreviewFilter(a: AlertDTO): boolean {
  return a.status == AlertStatus.Open
}

export enum AlertResolution {
	Duplicate = 'duplicate',
	False = 'false',
	NA = 'na',
	Resolved = 'resolved',
	Escalated = 'escalated'
}

export type UpdateAlertInterface = Pick<
	Partial<AlertDTO>,
	'severity' | 'status' | 'resolution' | 'snoozedUntilTime'
>

export interface AlertDTO extends AlertIdEmbed {
	creationTime: Date
	lastModifiedTime: Date
	subject: AlertSubject
	status: AlertStatus
	severity: AlertSeverity
	snoozedUntilTime: any
	resolution: AlertResolution | null
	resolvedBy: any
	patient: PatientIdEmbed | null | any
	alertedDevice: DeviceIdEmbed | null
}

export interface Alert extends AlertDTO {
  patient: PatientIdEmbed | PatientDTO | PatientInterface
  alertedDevice: DeviceIdEmbed | DeviceDTO | null
}

